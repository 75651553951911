.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  max-width: 90%;
  max-height: 100px;
  flex: 1;
}

.App-logo-container {
  width: 90vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 400px;
  max-height: 100px;
  border-radius: 30%;
  /*flex: 0;*/
}



.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}
