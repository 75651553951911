body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:
          radial-gradient(farthest-corner circle at 85% 200%, rgb(3, 128, 186) 0%, rgba(255,255,255,0.5) 50%, transparent),
          radial-gradient(farthest-corner circle at 100% 200%, rgb(3, 128, 186) 0%, rgba(255,255,255,1) 46%, transparent),
          radial-gradient(farthest-corner circle at 0px 0px, rgb(3, 128, 186) 0%, rgba(255,255,255,1) 50%, #dddddd);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

address {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-style: normal;
  font-size: 0.8em;
  color: #444444
}
